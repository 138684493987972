module.exports.backend = {
    apiUrl: "https://dev.interwin-scrapper.venturing.com.ar/Interwin-Scrapper/api",
};

module.exports.url="https://dev.interwin-web.venturing.com.ar"

module.exports.captcha = "6LemO8woAAAAAJuS_8fXFTM1NUqOyQDRwzMgYkbw";

module.exports.buildTimeoutInSeconds = 600;









